import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const Container = styled("div")(() => ({
  padding: 4,
  maxWidth: 330,
}));

const Image = styled("img")(({ theme }) => ({
  width: "130px",
  height: "130px",
  objectFit: "cover",
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

const NewCard = ({ src, category, detail, readtime }) => {
  return (
    <Container>
      <Stack direction='row' gap={3} alignItems='center'>
        <Image src={src} alt={detail} />
        <Stack justifyContent='space-between'>
          <Typography
            textTransform='uppercase'
            variant='body2'
            color='secondary'>
            {category}
          </Typography>
          <Typography mt={1} fontWeight={600} variant='body' color='primary'>
            {detail}
          </Typography>
          <Typography
            variant='body2'
            mt={1}
            sx={{ textDecoration: "underline" }}
            fontWeight={300}
            color='primary'>
            Estimated reading: {readtime} min
          </Typography>
        </Stack>
      </Stack>
    </Container>
  );
};

NewCard.propTypes = {
  src: PropTypes.string,
  category: PropTypes.string,
  detail: PropTypes.string,
  readTime: PropTypes.number,
};

NewCard.defaultProps = {
  src: "/",
  category: "no category",
  detail: "no details",
  readTime: "readtime not aviable",
};

export default NewCard;
