import {Typography} from "@mui/material";
import Card from "../Card";


const AppVisitorCard = () => {
   const listSelect = ["Last mounth", "Last 03 months" , "Last 06 months"];
   return <Card iconName="eye" title="Visitors" link='Do you want visits? Contact us!' listSelect={listSelect}>
          <Typography color="primary" variant="h2" >1824</Typography>
   </Card>
}

export default AppVisitorCard;
