import Card from "../Card";
import { Button, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Profile = styled("img")(() => ({
  width: "40px",
  height: "40px",
  borderRadius: "50%",
}));

const AppCostumerCard = () => {
  return (
    <Card iconName='headphones' title='Customer support'>
      <Stack mt={2} direction='row' gap={3} alignItems='center'>
        <Profile src='/static/images/profile.png' />
        <Typography variant='body' color='primary' fontSize={18}>
          Simone is here to help you.
        </Typography>
      </Stack>
      <Button
        variant='contained'
        sx={{ maxWidth: "168px", paddingY: 2, marginY: 2, bgcolor: "#21B8F9" }}>
        Contact us
      </Button>
    </Card>
  );
};

export default AppCostumerCard;
