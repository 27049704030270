import {
  Card,
  Stack,
  CardHeader,
  Typography,
  Grid,
  Skeleton,
} from "@mui/material";
import Icon from "../Icon";
import NewsCard from "./NewCard";
import axiosInstance from "../../api/axiosconfig";
import { useEffect, useState } from "react";

const Header = ({ iconName, title, link }) => {
  return (
    <Stack direction='row' justifyContent='space-between'>
      <Stack direction='row' gap={1.5} alignItems='center'>
        <Icon iconName={iconName} />
        <CardHeader sx={{ padding: 0, color: "primary" }} title={title} />
      </Stack>
      <Stack direction='row' gap={1.5} alignItems='center'>
        <a
          style={{ color: "#21B8F9" }}
          href={"https://www.lastingdynamics.com"}>
          <Typography sx={{ fontSize: 16, color: "#21B8F9" }}>
            {link}
          </Typography>
        </a>
        <Icon iconName='link-blue' />
      </Stack>
    </Stack>
  );
};

const AppNews = () => {
  const [newsList, setNewsList] = useState([]);
  useEffect(() => {
    (async function () {
      const { data } = await axiosInstance.get("fake-data.json");
      setTimeout(() => setNewsList(data.newList), 5000);
    })();
  }, []);
  return (
    <Card sx={{ padding: 3 }}>
      <Header iconName='doc' title='Latest news' link='Visit our blog' />
      <Grid mt={1} container spacing={4}>
        {newsList.length === 0 ? (
          <>
            <Grid item xs={12} sm={6} md={6}>
              <Skeleton variant='rectangular' width={280} height={118} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Skeleton variant='rectangular' width={280} height={118} />
            </Grid>
          </>
        ) : (
          newsList?.map(({ src, category, detail, readtime }, index) => (
            <Grid key={index} item xs={12} sm={6} md={6}>
              <NewsCard
                src={src}
                category={category}
                detail={detail}
                readtime={readtime}
              />
            </Grid>
          ))
        )}
      </Grid>
    </Card>
  );
};

export default AppNews;
