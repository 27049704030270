import {Card , Stack , Typography , Box} from "@mui/material";
import {styled} from "@mui/material/styles";
import Icon from "../Icon"
 

const CostumLink = styled("a")(() => ({
 color : "white",
 fontSize : 16
}))

const Link = ({text}) => {
   return (
    <Stack mt={2} direction="row" gap={1.5}>
      <CostumLink href="https://lastingdynamics.com">{text}</CostumLink>
      <Icon iconName="link" alt={text} />
    </Stack>
   )
}



const AppSellCard = () => {
   return (
    <Card sx={{maxWidth : "360px", minHeight : "220px", padding : 3 , bgcolor : "#F3A35C"}}>
     <Stack direction="row" justifyContent={"space-between"}>
        <Box component="div">
           <Typography variant="body" sx={{fontSize : 22 , fontWeight: 600, color : "white" , lineHeight: 1.4}}>Sell your products on your exclusive APP published on the stores </Typography>
           <Link text="See More"/>
        </Box>
        <img src="/static/images/iphone.png" alt="iphonepicture" />
     </Stack>

     <Stack mt={4} direction="row" justifyContent="space-between">
        <img src="/static/images/appstore.svg" alt="appstore" />
        <img src="/static/images/playstore.svg" alt="playstore" />
     </Stack>
    </Card>
   )
};

export default AppSellCard;