import {Box , Stack , Typography} from "@mui/material";
import {styled} from "@mui/material/styles"
import Card from "../Card";


const Container = styled("div")(() => ({
 marginTop : 12 , marginBottom : 12 , maxWidth : "155px" , display : "flex" , flexDirection : "column" , justifyContent : "center" , alignItems : "center"
}))


const Info = ({src , text , bg}) => {
 return <Container>
   <Box sx={{ display:"grid", borderRadius : 1.5 , placeItems: "center", height : 150 , width : 150 , backgroundColor :bg}}>
    <img height="60px" src={src} alt="noicon"  />
   </Box>
   <Typography textAlign="start" pl={1} mt={2} variant="body" color="primary" >{text}</Typography>
 </Container>
}


const AppExtensionCard = () => {
 return <Card title="Extension Marketplace" iconName="extenstion" link="Discover all extensions">
     <Stack direction="row" gap={2}>
      <Info src="/static/icons/domain.svg" text="Create your own domain" bg="#FFA26B" />
      <Info src="/static/icons/fifteen.svg" text="50 Additional Products " bg="#00C48C" />
     </Stack>    
 </Card>
};

export default AppExtensionCard;