import { useState } from "react";
import { styled } from "@mui/material/styles";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
}));

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  position: "relative",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 88,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const Layout = ({ children }) => {
  const [show, setShow] = useState(true);
  const toggle = () => {
    setShow(!show);
    console.log(!show)
  };
  return (
    <RootStyle>
      <DashboardNavbar show={show} />
      <DashboardSidebar toggleSidebar={toggle} />
      <MainStyle>{children}</MainStyle>
    </RootStyle>
  );
};

export default Layout;
