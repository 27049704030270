import Card from "../Card";
import {Stack, Typography , Box} from "@mui/material";
import PropTypes from "prop-types"

const Info = ({title , amount}) => {
 return (
   <Stack pt={1} direction="row" justifyContent="space-between" alignItems="center">
      <Typography color="info" variant="body">{title}</Typography>
      <Typography color="primary" variant="h6">{amount}</Typography>
   </Stack>
 )
};

Info.propTypes = {
 title : PropTypes.string,
 amount : PropTypes.string
}

Info.defaultProps = {
 title : "No info",
 amount : "0"
}


const AppOrderCard = () => {
  const listSelect = ["Last mounth", "Last 03 months" , "Last 06 months"];
 return (
  <Card listSelect={listSelect} iconName="order" title="Orders" link="10 free tips to increase your sales">
    <Box>
    <Info title="Orders received:" amount={"156"}/>
    <Info title="Earnings:" amount={"€ 1893,24"}/>
    </Box>
  </Card>
 )
};

export default AppOrderCard;