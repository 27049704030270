import { Stack, Typography } from "@mui/material";
import Card from "../Card";
import PropTypes from "prop-types";

const Info = ({ title, amount }) => {
  return (
    <Stack pt={1}>
      <Typography color='info' variant='body'>
        {title}
      </Typography>
      <Typography color='primary' variant='h6'>
        {amount}
      </Typography>
    </Stack>
  );
};

Info.propTypes = {
  title: PropTypes.string,
  amount: PropTypes.string,
};

Info.defaultProps = {
  title: "No info",
  amount: "0",
};

const AppConfigCard = () => {
  return (
    <Card title='Configure your shop' iconName='tool' link='Complete set up'>
      <Typography
        paddingY={1}
        sx={{ fontSize: 24, color: "#FFA26B", fontWeight: 800 }}>
        45%
        <span style={{ display: "block" , fontSize : 16 , lineHeight : 1 }}>Completed</span>
      </Typography>
      <Typography variant='body' paddingY={2}>
        Complete all the steps to have a complete shop to best present to your
        customers.
      </Typography>
    </Card>
  );
};

export default AppConfigCard;
