import Header from "../../components/Header";
import { Container, Grid , Stack } from "@mui/material";
import {
  AppVisitors,
  AppOrders,
  AppConfig,
  AppSell,
  AppExtesion,
  AppTrust,
  AppNews,
  AppInvit,
  AppCostumer,
} from "../../components/_dashboard/";

const Dashboard = () => {
  return (
    <Container maxWidth='xl'>
      <Header />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <AppVisitors />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppOrders />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppConfig />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppSell />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppExtesion />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AppTrust />
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <AppNews />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Stack spacing={4}>
            <AppInvit />
            <AppCostumer />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
