import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Icon from "./Icon";

const RootStyle = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "56px",
  right: 0,
  left: 0,
  background:
    "linear-gradient(357deg, rgba(33,184,249,0.35301120448179) 10%, rgba(0,212,255,1) 100%)",
}));

const InnerContainer = styled("div")(({ theme }) => ({
  height: "182px",
  padding: "2rem 4rem",
}));

const Link = styled("a")(() => ({
  textDecoration: "none",
  color: "white",
}));

const Header = () => {
  return (
    <RootStyle>
      <InnerContainer>
        <Stack
          direction='row'
          sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant='h3' color='white'>
            Welcome Mario!
          </Typography>
          <Stack alignItems="center" direction='row' gap={2}>
            <Link href='https://app.vetrinalive.it/fenoh-store' target='_blank'>
              <Typography variant='h6' color='white'>
                app.vetrinalive.it/fenoh-store
              </Typography>
            </Link>
            <Icon iconName='link' alt='link-icon' />
          </Stack>
        </Stack>
      </InnerContainer>
    </RootStyle>
  );
};

export default Header;
