import Card from "../Card";
import { Typography } from "@mui/material";

const AppInvitCard = () => {
  return (
    <Card
      title='Costumer support'
      iconName='users'
      link='Start inviting friends!'>
      <Typography fontSize={17} variant='body'>
        <span style={{ color: "#00C48C", fontWeight: "600" }}>
          Receive 50 products
        </span>
        by inviting a friend who subscribes to a plan. Your friend will receive
        a 30% discount coupon valid for any plan.
      </Typography>
    </Card>
  );
};

export default AppInvitCard;
