import { AppBar, Toolbar, Typography, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import Icon from "../../components/Icon";

const DRAWER_WIDTH = 278;
const APPBAR_MOBILE = 44;
const APPBAR_DESKTOP = 60;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backgroundColor: "white",
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const InnerContainer = styled("div")(() => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const DashboardNavbar = ({ show }) => {
  return (
    <RootStyle
      sx={{ width: show ? "100%" : `calc(100% - ${DRAWER_WIDTH + 1}px)` }}>
      <ToolbarStyle>
        <InnerContainer>
          <Typography variant='body' fontWeight={"600"} color={"primary"}>
            Dashboard
          </Typography>
          <Stack position='relative' direction='row' alignItems='center'>
            <Icon iconName='new' alt='new-icon' />
            <Typography
              variant='body2'
              sx={{ fontWeight: "550", cursor: "pointer" }}
              color={"primary"}>
              What's new?
            </Typography>
            <div
              style={{
                position: "absolute",
                color: "white",
                top: "-4px",
                right: "-12px",
                width: "20px",
                display: "grid",
                placeItems: "center",
                height: "20px",
                backgroundColor: "red",
                borderRadius: "50%",
              }}>
              <Typography>4</Typography>
            </div>
          </Stack>
        </InnerContainer>
      </ToolbarStyle>
    </RootStyle>
  );
};

export default DashboardNavbar;
