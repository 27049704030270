import { Card as Cart, CardHeader, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Icon from "./Icon";
import Select from "./Select";

const Card = ({ title, link, iconName, listSelect, isDark, children }) => {
  return (
    <Cart
      sx={{
        maxWidth: "360px",
        minHeight: "220px",
        padding: 3,
        backgroundColor: isDark ? "#000032" : "white",
        color: isDark ? "white" : "primary",
      }}>
      <Stack
        gap={0.5}
        sx={{ justifyContent: "space-between", minHeight: "180px" }}>
        <Stack
          direction='row'
          sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Stack direction='row' gap={1.5}>
            <Icon iconName={iconName} />
            <CardHeader sx={{ padding: 0 }} title={title} />
          </Stack>
          {listSelect && (
            <Select variant={"standard"} listSelect={[...listSelect]} />
          )}
        </Stack>

        {children}

        {link && (
          <Stack direction='row' gap={1.5} alignItems='center'>
            <a
              style={{ color: isDark ? "#00C48C" : "#21B8F9" }}
              href={"https://www.lastingdynamics.com"}>
              <Typography
                sx={{ fontSize: 16, color: isDark ? "#00C48C" : "#21B8F9" }}>
                {link}
              </Typography>
            </a>
            <Icon iconName={isDark ? "link-green" : "arrow-right"} />
          </Stack>
        )}
      </Stack>
    </Cart>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  listSelect: PropTypes.array,
  iconName: PropTypes.string,
  isDark: PropTypes.bool,
};

Card.defaultProps = {
  title: "No title provided",
  iconName: "eye",
};

export default Card;
