const mainMenu = [{
    title: "Dashboard",
    icon: "home"
  }, {
    title: "Catalogue",
    icon: "catalogue"
  },
  {
    title: "Orders",
    icon: "order"
  },
  {
    title: "Marketing",
    icon: "marketing"
  }, {
    title: "Delivery Option",
    icon: "delivery"
  }, {
    title: 'Payment Options',
    icon: "payment"
  },
  {
    title: "Store Design",
    icon: "store-design"
  }, {
    title: "Subscription",
    icon: "subscription"
  },
  {
    title: "Integration",
    icon: "integration"
  },
  {
    title: "Extensions",
    icon: "extenstion"
  },
  {
    title: "Setting",
    icon: "settings"
  },
  {
    title: "Log out",
    icon: "log-out"
  }

];
const secondMenu = [{
    title: "Costumer Support",
    icon: "costumer-help"
  },
  {
    title: "Share your shop",
    icon: "share"
  },
  {
    title: "View your shop",
    icon: "eye"
  }
]



export {
  mainMenu,
  secondMenu
};