import { Stack, Divider, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Logo from "./Logo";
import Icon from "../../components/Icon";
import NavSection from "../../components/NavSection";
import { mainMenu, secondMenu } from "./SidebarConfig";
import Select from "../../components/Select";

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  position: "relative",
  [theme.breakpoints.up("xs")]: {
    flexShrink: 0,
    width: "40%",
  },
  [theme.breakpoints.up("md")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const TopBar = () => {
  return (
    <Stack
      direction='row'
      sx={{
        backgroundColor : "white",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 2,
       
      }}>
      <Logo />
      <Icon iconName='menu' alt='Menu-icon' />
    </Stack>
  );
};

const SelectShop = () => {
  return (
    <Box component='div' marginTop={6}>
      <Typography
        color='primary'
        sx={{ fontSize: 14, fontWeight: "600", px: 2.5 }}>
        Select your shop
      </Typography>
      <Select
        variant='outlined'
        listSelect={["Fenoh Store", "Other Store"]}
        full
      />
    </Box>
  );
};

const DashboardSidebar = ( ) => {
  return (
    <RootStyle>
      <TopBar />
      <NavSection navConfig={mainMenu} />
      <Divider sx={{ paddingTop: 1.5 }} />
      <NavSection navConfig={secondMenu} />
      <SelectShop />
    </RootStyle>
  );
};

export default DashboardSidebar;
