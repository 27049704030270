import {styled} from "@mui/material/styles";

const CostumIcon = styled("img")(() => ({

 padding : 4
}))


const Logo = ( ) => {
 return (
  <CostumIcon  src="static/icons/logo.svg" alt={"logo"} />
 )
};

 

export default Logo; 