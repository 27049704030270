import Card from "../Card";
import { Typography } from "@mui/material";

const AppTrustCard = () => {
  return (
    <Card
      title='Trustpilot'
      isDark
      iconName='star'
      link='Write a review on Trustpilot'>
      <Typography
        sx={{ fontSize: 18, fontWeight: 550, color: "white" }}
        variant='body'>
        Show us your love by leaving a
        <span style={{ color: "#00C48C" , fontWeight : 700 }}> positive</span> review on trust
        pilot and receive the extension of 50 additional products
      </Typography>
    </Card>
  );
};

export default AppTrustCard;
