import React from "react";
import ThemConfig from "../theme";
import ScrollTop from "../components/ScrollTop";
import GlobalStyles from "../theme/globalStyles";
import Layout from "../layout/Dashboard";
import Dashboard from "../pages/Dashboard/Dashboard";

const App = () => {
    return ( <
        ThemConfig >
        <
        ScrollTop / >
        <
        GlobalStyles / >
        <
        Layout >
        <
        Dashboard / >
        <
        /Layout> <
        /ThemConfig>
    );
};

export default App;