import {Box , List , useTheme , Collapse , ListItemButton ,  ListItemIcon , ListItemText } from "@mui/material";
import {alpha , styled} from "@mui/material/styles"
import PropTypes from "prop-types";
import {useState} from "react";
import Icon from "../components/Icon"


const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
 ({ theme }) => ({
   ...theme.typography.body2,
   height: 40,
   position: 'relative',
   textTransform: 'capitalize',
   paddingLeft: theme.spacing(2.5),
   paddingRight: theme.spacing(2.5),
   color: theme.palette.text.secondary,
   '&:before': {
     top: 0,
     right: 0,
     width: 3,
     bottom: 0,
     content: "''",
     display: 'none',
     position: 'absolute',
     borderTopLeftRadius: 4,
     borderBottomLeftRadius: 4,
     backgroundColor: theme.palette.primary.main
   }
 })
);

function NavItem({ item }) {
 const { title, icon } = item;
   return (
     <>
       <ListItemStyle
       >
         <Icon iconName={icon} alt={title}  />
         <ListItemText sx={{paddingLeft : 2}} disableTypography primary={title} />
       </ListItemStyle>
     </>
   );
}



const NavSection = ({navConfig} ) => {
 return <Box>
  <List>
   {
    navConfig.map(item => <NavItem key={item.title} item={item} />)
   }
  </List>
 </Box>
}

NavSection.propTypes = {
 navConfig : PropTypes.array
}

export default NavSection;