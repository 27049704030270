import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";

const CostumIcon = styled("img")(() => ({
  height: 25,
  width: 25,
  padding: 2,
  display: "inline",
}));

const Icon = ({ iconName, alt }) => {
  return (
    <IconButton>
      <CostumIcon src={`static/icons/${iconName}.svg`} alt={alt} />
    </IconButton>
  );
};

Icon.propTypes = {
  iconName: PropTypes.string,
};

Icon.defaultProps = {
  iconName: "",
  alt: "No Icon provided!",
};

export default Icon;
