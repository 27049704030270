import * as React from "react";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import PropTypes from "prop-types";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    fontSize: 16,
    padding: "10px 30px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export default function CustomizedSelects({ variant, listSelect, full }) {
  const [age, setAge] = React.useState(listSelect[0]);
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <FormControl
      sx={{ width: full ? "100%" : "auto", padding: full ? 2 : 0 }}
      variant={variant}>
      <Select value={age} onChange={handleChange} input={<BootstrapInput />}>
        {listSelect?.map((item) => (
          <MenuItem key={item} value={10}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

CustomizedSelects.propTypes = {
  variant: PropTypes.string,
  listSelect: PropTypes.array,
};
